import type { IContentItemsContainer } from '@kontent-ai/delivery-sdk'
import type {
  InterestedTopicModel,
  NavigationItemModel,
  NavigationSectionModel,
  ScriptTagModel,
  SiteConfigurationModel,
  SocialMediaLinkModel
} from '~/models'
import type { KontentPageRoute } from '~/types/KontentPageRoute'
import type { NavigationItem } from '~/types/NavigationItem'

interface SiteState {
  site: SiteConfigurationModel
  fetched: boolean
  linkedItems: IContentItemsContainer
  pages: KontentPageRoute[]
}

export const useSiteStore = defineStore('site', {
  state: (): SiteState => ({
    site: {} as SiteConfigurationModel,
    fetched: false,
    linkedItems: {} as IContentItemsContainer,
    pages: [] as KontentPageRoute[]
  }),
  actions: {
    async fetchData() {
      if (!this.fetched) {
        const response = await $fetch('/api/site-configuration')
        const pagesResponse = await $fetch('/api/navigation/pages')
        if (response && pagesResponse) {
          //@ts-ignore
          this.site = response.data.item as SiteConfigurationModel
          //@ts-ignore
          this.linkedItems = response.data.linkedItems as IContentItemsContainer
          this.pages = pagesResponse.data as KontentPageRoute[]
          this.fetched = true
        }
      }
    }
  },
  getters: {
    scriptTags: (state) => {
      const tags: ScriptTagModel[] = []

      if (state.fetched) {
        for (const item of state.site.elements.scriptTags.value) {
          const linkedItem = state.linkedItems?.[item] as ScriptTagModel
          if (!linkedItem) continue
          tags.push(linkedItem)
        }
      }

      return tags
    },
    getLinkedItem:
      (state) =>
      <T>(codename: string) => {
        return state.linkedItems?.[codename] as T
      },
    socialLinks: (state) => {
      if (!state.fetched) return []
      const socialLinks: SocialMediaLinkModel[] = []
      for (const item of state.site.elements.socialMediaLinks.value) {
        const linkedItem = state.linkedItems?.[item] as SocialMediaLinkModel
        if (!linkedItem) continue
        socialLinks.push(linkedItem)
      }
      return socialLinks
    },
    getUrlByCodename: (state) => (codename: string) => {
      if (!state.fetched) return ''
      const page = state.pages.find((x) => x.codename === codename)
      return page?.path ?? ''
    },
    getUrlByItemId: (state) => (itemId: string) => {
      if (!state.fetched) return ''
      const page = state.pages.find((x) => x.itemid === itemId)
      return page?.path ?? ''
    },
    getPage: (state) => (urlPath: string, itemId?: string | null) => {
      if (!state.fetched) return null
      if (itemId) {
        return state.pages.find((x) => x.itemid === itemId)
      }
      return state.pages.find((x) => x.path === urlPath)
    },
    interestedTopics: (state) => {
      const items: InterestedTopicModel[] = []
      for (const item of state.site.elements.formInterestedTopics.value) {
        const linkedItem = state.linkedItems?.[item] as InterestedTopicModel
        if (!linkedItem) continue
        items.push(linkedItem)
      }
      return items
    },
    getHeaderNavigationItems: (state) => (): NavigationItem[] => {
      if (!state.fetched) return []

      const navigationItems: NavigationItem[] = []

      if (state.fetched) {
        const headerCodename = state.site.elements.navigationHeader?.value[0]
        const navigation = state.linkedItems?.[headerCodename] as NavigationSectionModel

        for (const item of navigation.elements.subItems.value) {
          const linkedItem = state.linkedItems?.[item] as NavigationItemModel
          if (!linkedItem) continue

          let url = ''

          if (linkedItem.elements.page.value.length > 0) {
            const pageCodename = linkedItem.elements.page.value[0]
            //@ts-ignore
            url = state.getUrlByCodename(pageCodename)
          } else {
            //@ts-ignore
            url = linkedItem.elements.externalUrl.value
          }

          navigationItems.push({
            title: linkedItem.elements.title.value,
            url: url,
            subItems: getSubItems(linkedItem)
          } as NavigationItem)
        }

        function getSubItems(this: any, item: any) {
          let subItems: NavigationItem[] = []

          if (item.elements.subItems && item.elements.subItems.value.length > 0) {
            for (const codename of item.elements.subItems.value) {
              const linkedItem = state.linkedItems?.[codename] as NavigationItemModel
              if (!linkedItem) continue

              let url = ''
              if (linkedItem.elements.page.value.length > 0) {
                const pageCodename = linkedItem.elements.page.value[0]
                //@ts-ignore
                url = state.getUrlByCodename(pageCodename)
              } else {
                //@ts-ignore
                url = linkedItem.elements.externalUrl.value
              }

              subItems.push({
                title: linkedItem.elements.title.value,
                url: url,
                subItems: getSubItems(linkedItem)
              } as NavigationItem)
            }
          }
          return subItems
        }
      }
      return navigationItems
    },
    getFooterNavigationItems: (state) => (): NavigationItem[] => {
      const navigationItems: NavigationItem[] = []
      if (state.fetched) {
        const footerCodename = state.site.elements.navigationFooter.value[0]
        const navigation = state.linkedItems?.[footerCodename] as NavigationSectionModel

        for (const item of navigation.elements.subItems.value) {
          const linkedItem = state.linkedItems?.[item] as NavigationItemModel
          if (!linkedItem) continue

          let url = ''

          if (linkedItem.elements.page.value.length > 0) {
            //@ts-ignore
            const pageCodename = linkedItem.elements.page.value[0]
            //@ts-ignore
            url = state.getUrlByCodename(pageCodename)
          } else {
            //@ts-ignore
            url = linkedItem.elements.externalUrl.value
          }

          navigationItems.push({
            title: linkedItem.elements.title.value,
            url: url,
            subItems: getSubItems(linkedItem)
          } as NavigationItem)
        }

        function getSubItems(item: any) {
          let subItems: NavigationItem[] = []

          if (item.elements.subItems && item.elements.subItems.value.length > 0) {
            for (const codename of item.elements.subItems.value) {
              const linkedItem = state.linkedItems?.[codename] as NavigationItemModel
              if (!linkedItem) continue

              let url = ''
              if (linkedItem.elements.page.value.length > 0) {
                const pageCodename = linkedItem.elements.page.value[0]
                //@ts-ignore
                url = state.getUrlByCodename(pageCodename)
              } else {
                //@ts-ignore
                url = linkedItem.elements.externalUrl.value
              }

              subItems.push({
                title: linkedItem.elements.title.value,
                url: url,
                subItems: getSubItems(linkedItem)
              } as NavigationItem)
            }
          }
          return subItems
        }
      }

      return navigationItems
    }
  }
})

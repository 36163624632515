<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <BackToTop />
</template>
<script lang="ts" setup>
const siteStore = useSiteStore()
await siteStore.fetchData()
</script>
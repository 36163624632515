export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    const siteStore = useSiteStore()
    let scripts: Array<string> = []
    if (siteStore.fetched) {
      for (const script of siteStore.scriptTags) {
        scripts.push(script.elements.innerCode.value)
      }

      useHead(() => {
        return {
          script: scripts
        }
      })
    }
  })
})

<template>
    <div class="js-back-to-top" v-show="model.visible.value">
        <div class="back-to-top back-to-top--is-active"><button class="btn btn--primary" @click="backToTop">Back to top<span
                    class="btn__icon"><svg class="icon icon--up-arrow" data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.95 6.06">
                        <path
                            d="M5.85 2.6L3.35.15 3.23.07A.55.55 0 002.95 0a.59.59 0 00-.26.07l-.08.08L.11 2.6a.35.35 0 000 .5.35.35 0 00.5 0l2-2v4.6a.35.35 0 00.34.36.34.34 0 00.34-.34v-4.6l2 2a.36.36 0 00.51 0 .37.37 0 00.05-.52z"
                            fill="currentcolor"></path>
                    </svg></span></button></div>
    </div>
</template>
<script lang="ts" setup>

const model = {
    visible: ref(false),
}

function onScroll() {

    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        model.visible.value = true;
    } else {
        model.visible.value = false;
    }
}

function backToTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

onMounted(() => {

    window.onscroll = () => onScroll();
})

</script>
<style lang="scss" scoped>
@use "~/assets/scss/back-to-top.scss";
</style>